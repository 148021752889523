import { LazyQueryResultTuple, gql } from '@apollo/client';
import {
  GetReservationBySurnameQuery,
  GetReservationBySurnameQueryVariables,
} from './__generated__/get-reservation-by-surname.generated';
import { useLazyQueryErrorNotification } from 'src/graphql/hooks/useLazyQueryErrorNotification';

export const GET_RESERVATION_BY_SURNAME_QUERY = gql`
  query GetReservationBySurname($reference: String, $reservationId: Float, $surname: String!) {
    getReservationBySurname(reference: $reference, reservationId: $reservationId, surname: $surname) {
      id
      reference
      contactName
      contactPhone
      contactEmail
      status
      createdAt
      tickets {
        passenger {
          id
          fullName
          seat {
            number
          }
          type
        }
      }
      schedule {
        route {
          branch {
            name
            address {
              state
            }
          }
          destination {
            name
            state
          }
        }
      }
      trip {
        id
        isCheckinAvailable
        dateTime
      }
    }
  }
`;

export const useLazyGetReservationBySurnameQuery = (): LazyQueryResultTuple<
  GetReservationBySurnameQuery,
  GetReservationBySurnameQueryVariables
> =>
  useLazyQueryErrorNotification<GetReservationBySurnameQuery, GetReservationBySurnameQueryVariables>(
    GET_RESERVATION_BY_SURNAME_QUERY,
    { fetchPolicy: 'network-only' }
  );
